body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #e5e5e5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: Roboto, sans-serif;
  box-sizing: border-box;
}

.math-inline {
  display: inline-block !important;
}

.ck-editor__editable {
  height: 420px;
  overflow-y: auto !important;
  scrollbar-color: #9fb6c1 #eae8e8;
  scrollbar-width: thin !important;
  white-space: pre-wrap !important;
}

.ck-editor__editable::-webkit-scrollbar {
  width: 4px !important;
  background-color: #eae8e8 !important;
}
.ck-editor__editable::-webkit-scrollbar-thumb {
  background-color: #9fb6c1 !important;
  border-radius: 2 !important;
}
.ck-editor__editable::selection {
  color: #303030 !important;
  background: #c5d7e0 !important;
}

.slectParagraph {
  color: #303030;
  background-color: #c5d7e0;
}
.text-editorParse {
  display: inline-block;
}

.text-editorParse * {
  display: inline-block;
}
